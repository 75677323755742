<template>
  <div>
    <vx-card class="mt-base" no-shadow card-border>
      <h2>เปิดใช้งาน 2 FA</h2>
      <div v-if="!hadGen" class="text-center">
        <!-- <vs-button  color="success" type="relief" class="mt-8" @click="get2Fac"
          >เปิดใช้งาน</vs-button
        > -->
        <br />
        <div >
          <h3 class="mt-2 mb-2">step 1</h3>
          <span class="mb-2"
            >เปิดแอพ Google Authenticator เพื่อสแกน QR CODE
          </span>
          <br />
          <br />
          <canvas ass="mt-2 mb-2" id="canvas"></canvas>
          <br />
          <br />
          <span
            >หากท่านไม่สามารถสแกน QR code ได้
            โปรดป้อนคีย์ด้านล่างนี้ด้วยตนเองลงในแอป</span
          >
          <vs-input
            class="w-full"
            label-placeholder="คีย์"
            v-model="secretKey"
            name="secretKey"
            disabled
          />
          <small class="text-danger"
            >***โปรดเก็บ QR Code หรือคีย์ ไว้ในที่ปลอดภัย
            ท่านสามารถใช้กู้คืนบัญชีของท่านด้วย QR code นี้
            ในกรณีที่ท่านลบข้อมูลออกโดยไม่ได้ตั้งใจ
            หรือโทรศัพท์มือถือสูญหาย</small
          >
          <h3 class="mt-2 mb-2">step 2</h3>
          <span>ใส่รหัส 6 หลัก ที่ได้จากแอพ</span>
          <vs-input
            class="w-full"
            label-placeholder="รหัส 6 หลัก"
            v-model="token"
            name="otp"
          />
          <vs-button color="success" type="relief" class="mt-8" @click="verifyToken"
            >ยืนยันรหัส</vs-button
          >
        </div>
      </div>
      <div v-else class="text-center">
          <br>
          <span>คุณเปิดใช้งาน 2FACTOR แล้ว</span>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../axios'
import QRCode from 'qrcode'
export default {
  name: 'Profile',
  data () {
    return {
      dataStaffAuth: '',
      hadGen: false,
      dataGen2Fac: '',
      secretKey: '',
      twoFacUrl: '',
      token: '',
      dataVerify2Fac: ''
    }
  },
  async mounted () {
    await axios
      .get('twofactor/checkstaffauth')
      .then(response => (this.dataStaffAuth = response.data))
    // console.log('dawda ', this.dataStaffAuth.admin_auth)
    // console.log('this.dataStaffAuth.admin_auth.length', this.dataStaffAuth.admin_auth.length)
    if (this.dataStaffAuth.admin_auth !== null) {
    //   console.log('sadasdadd')
      if (this.dataStaffAuth.admin_auth.length > 0) {
        this.hadGen = true
      } else {
        this.$vs.loading()
        await this.get2Fac()
        this.$vs.loading.close()
      }
    } else {
    //   console.log('1l3ierhoie')
      this.$vs.loading()
      await this.get2Fac()
      this.$vs.loading.close()
    }
    
  },
  methods: {
    async get2Fac () {
      await axios
        .get('twofactor/gen2fac')
        .then(response => (this.dataGen2Fac = response.data.url))
      this.secretKey = this.dataGen2Fac.base32
      this.twoFacUrl = this.dataGen2Fac.otpauthUrl
      await this.genQRcode(this.twoFacUrl)
      
    },
    async genQRcode (url) {
      const canvas = document.getElementById('canvas')
      await QRCode.toCanvas(canvas, url, function (error) {
        if (error) console.error(error)
        console.log('success!')
      })
    },
    async verifyToken () {
      this.$vs.loading()
      await axios
        .post('twofactor/verify2fac', {
          secretKey: this.secretKey,
          token: this.token
        })
        .then(response => (this.dataVerify2Fac = response.data))
      if (this.dataVerify2Fac.statusVerify) {
        this.$vs.loading.close()
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        this.$vs.notify({
          time: 16000,
          color: 'success',
          position: 'top-right',
          icon: 'success',
          title: 'เปิดใช้งาน 2 Factor สำเร็จ',
          text: 'กรุณารักษาคีย์ในที่ปลอดภัย'
        })
        this.$store.commit('UPDATE_USER_INFO', {
          isAuthenticated: true
        }, {
          root: true
        })
      } else {
        this.$vs.loading.close()
        this.$vs.notify({
          time: 15000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'เปิดใช้งาน 2 Factor ไม่สำเร็จ',
          text: 'รหัส 6 หลัก ไม่ถูกต้อง กรุณาลองอีกครั้ง'
        })
      }
    }
  }
}
</script>

<style></style>